import { Grid, IconButton, useTheme } from "@mui/material";
import React, { RefObject, useEffect, useLayoutEffect, useRef, useState } from "react";
import IconDialogMain from "../../../../components/Dialog/IconDialog/IconDialogMain";
import TooltipDimension from "../../../../components/TooltipDimension/TooltipDimension";
import { ABB } from "../../../../constants/Theme";
import { EFontPixelSize, arrFontSize } from "../../../../enum/EFontSize";
import { EInsertType } from "../../../../enum/EInsertType";
import { IElementSize } from "../../../../interfaces/DTO/IElementSize";
import { EInsertDetailItemType, IInsertDetail, IInsertDetailItemDimension } from "../../../../interfaces/IInsertDetail";
import { ITileDimension } from "../../../../interfaces/ITileDimension";
import IconHandler from "../IconHandler/IconHandler";
import { tileRefs } from "../InsertConfiguration";
import Canvas, { ICanvasForwardProps } from "./Canvas";
import { IDimension } from "./DimensionLine";
import { IRockerTextLabelProps, ITextLabelSizes, LimitChar, isHorizontal } from "./limitChar";
import { ONE_PIXEL_IN_MILLITER, getElementPosition, getMatchedPercentSize } from "./utils";
import CoverItemsFunctions from "./utils-cover-items";
import CoverItemsRefsFunctions from "./utils-cover-items-refs";
import { FTSTheme } from "../../../../App";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { IIcon } from "../../../../interfaces/IIcon";
import { IHandleTextInputChangeParams } from "../../../../interfaces/ITextAreaControlled";
import { useStoreState } from "../../../../hooks";

interface IIconTileProps {
  isHorizontal: boolean;
  designCode: string;
  cover: number;
  textLabelSizes: ITextLabelSizes | IRockerTextLabelProps;
  indexDetail: string;
  tileDimension: ITileDimension;
  insertDetail: IInsertDetail;
  tileName?: string;
  setInsertDetail: (detail: IInsertDetail) => void;
  isRocker: boolean;
  tileType?: EInsertType;
  coverRef?: RefObject<HTMLDivElement>;
  canManageRef: boolean;
}

const TileConfiguration: React.FC<IIconTileProps> = (props) => {
  const theme = useTheme() as FTSTheme;
  const [recentlyIcons, setRecentlyIcons] = useLocalStorage<IIcon[]>("@FTS:Icons", []);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [textAreaValue, setTextAreaValue] = useState<string>("");
  const [textAreaPreviewValue, setTextAreaPreviewValue] = useState<string>("");
  const [currentCanvasValue, setCurrentCanvasValue] = useState<string>("");
  const [fontSizeCanvas, setFontSizeCanvas] = useState(18);
  const [indexFontSize, setIndexFontSize] = useState(0);
  const [iconDimension, setIconDimension] = useState<IInsertDetailItemDimension>();
  const [showCanvas, setShowCanvas] = useState(false);
  const [lineHeight, setLineHeight] = useState(fontSizeCanvas * 1.2);
  const [displayHighlightedWarning, setDisplayHighlightedWarning] = useState<{
    display: boolean;
    start: number;
    end: number;
  }>({ display: false, start: 0, end: 0 });
  const { designSizes } = useStoreState((state) => state.designs.data);

  const arrLimitTextLabel = [
    props.textLabelSizes.small,
    props.textLabelSizes.standard,
    props.textLabelSizes.medium,
    props.textLabelSizes.large,
    props.textLabelSizes.xlarge,
  ];

  const canvasComponentRef = useRef<ICanvasForwardProps>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const initialTextArea =
      props.insertDetail.items?.find(
        (item) => item.type == EInsertDetailItemType.Text && item.indexDetail == props.indexDetail
      )?.textValue ?? "";

    const initialFontSize =
      props.insertDetail.items?.find(
        (item) => item.type == EInsertDetailItemType.Text && item.indexDetail == props.indexDetail
      )?.font?.size ?? props.tileDimension.fontSizeDefault;

    const initialIconDimensionItem = getInitialIconDimension();

    const property = initialFontSize as EFontPixelSize;
    const initialIndexFontSize = arrFontSize.indexOf(property) == -1 ? 1 : arrFontSize.indexOf(property);

    setIndexFontSize(initialIndexFontSize);
    setIconDimension(initialIconDimensionItem);
    setFontSizeCanvas(initialFontSize);
    setTextAreaValue(initialTextArea);
    setLineHeight(initialFontSize);
    setShowCanvas(initialTextArea != "");

    canvasComponentRef.current?.reDraw(
      initialFontSize,
      initialFontSize,
      initialTextArea,
      initialIndexFontSize,
      initialFontSize * arrLimitTextLabel[indexFontSize].maxRows + 10,
      false,
      props.insertDetail
    );

    CoverItemsFunctions.initialItems(props.insertDetail, props.setInsertDetail);
  }, []);

  useEffect(() => {
    if (canvasComponentRef.current != null) {
      let arrayOfRefs = tileRefs[props.cover] ?? {};
      arrayOfRefs[props.indexDetail] = canvasComponentRef;
      tileRefs[props.cover] = arrayOfRefs;
    }
  }, [canvasComponentRef]);

  useEffect(() => {
    async function addIconRef() {
      await CoverItemsRefsFunctions.addOrUpdateItemRef({
        indexDetail: props.indexDetail,
        coverIndex: props.cover,
        type: EInsertDetailItemType.Icon,
        coverRef: props.coverRef!,
        itemRef: iconRef,
      });
    }

    async function addCanvasRef() {
      await CoverItemsRefsFunctions.addOrUpdateItemRef({
        indexDetail: props.indexDetail,
        coverIndex: props.cover,
        type: EInsertDetailItemType.Text,
        coverRef: props.coverRef!,
        itemRef: canvasComponentRef,
      });
    }

    if (iconRef.current != null && props.canManageRef) {
      addIconRef();
    }

    if (canvasComponentRef.current != null && props.canManageRef) {
      addCanvasRef();
    }
  }, [iconRef, canvasComponentRef, props.insertDetail.rotate]);

  useEffect(() => {
    return () => {
      tileRefs[props.cover] = [];
    };
  }, []);

  useEffect(() => {
    const positions = getElementPosition(props.coverRef!, iconRef, getIconSize);
    iconRef.current?.setAttribute("data-xmm", positions?.xmm!?.toString());
    iconRef.current?.setAttribute("data-ymm", positions?.ymm!?.toString());

    const iconChangedIndexDetail = props.insertDetail.items.find(
      (item) => item.type == EInsertDetailItemType.Icon && item.indexDetail == props.indexDetail
    );

    if (iconChangedIndexDetail) {
      const { insertType } = props.insertDetail;
      const matchedSize = getMatchedPercentSize(iconChangedIndexDetail, insertType!);
      CoverItemsFunctions.addOrUpdateIcon({
        iconId: iconChangedIndexDetail?.iconId!,
        iconName: iconChangedIndexDetail?.iconName!,
        materialNumber: iconChangedIndexDetail?.materialNumber!,
        indexDetail: props.indexDetail,
        insertDetail: props.insertDetail!,
        setInsertDetail: props.setInsertDetail,
        coverIndex: props.cover,
        color: iconChangedIndexDetail?.color!,
        designSizes: designSizes,
        iconSizePercentage: Number(matchedSize),
      });
    }
  }, [props.insertDetail.rotate]);

  const getInitialIconDimension = () => {
    if (props.insertDetail && props.insertDetail.items != null) {
      const hasItem = props.insertDetail.items.find(
        (item) => item.type == EInsertDetailItemType.Icon && item.indexDetail == props.indexDetail
      );

      if (hasItem) {
        const initial: IInsertDetailItemDimension = {
          width: hasItem.dimension?.width! / ONE_PIXEL_IN_MILLITER,
          height: hasItem.dimension?.height! / ONE_PIXEL_IN_MILLITER,
        };

        return initial;
      }
    }

    const initial: IInsertDetailItemDimension = {
      width: props.tileDimension.iconPixelDefault,
      height: props.tileDimension.iconPixelDefault,
    };

    return initial;
  };

  const handleOpenIconbox = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleDecreaseFont = () => {
    setIndexFontSize((prev) => prev - 1);
    const fontSizeDecremented = arrFontSize[indexFontSize - 1];

    setFontSizeCanvas(fontSizeDecremented);
    setLineHeight(fontSizeDecremented);
    canvasComponentRef.current?.reDraw(
      fontSizeDecremented,
      fontSizeDecremented,
      textAreaValue,
      indexFontSize - 1,
      fontSizeDecremented * arrLimitTextLabel[indexFontSize].maxRows + 10,
      true,
      props.insertDetail
    );
  };

  const handleIncreaseFont = () => {
    setIndexFontSize((prev) => prev + 1);
    const fontSizeIncremented = arrFontSize[indexFontSize + 1];
    setFontSizeCanvas(fontSizeIncremented);
    setLineHeight(fontSizeIncremented);

    canvasComponentRef.current?.reDraw(
      fontSizeIncremented,
      fontSizeIncremented,
      textAreaValue,
      indexFontSize + 1,
      fontSizeIncremented * arrLimitTextLabel[indexFontSize].maxRows + 10,
      true,
      props.insertDetail
    );
  };

  const handleResetAll = (insertDetail: IInsertDetail) => {
    setIndexFontSize(1);
    const fontSizeIncremented = arrFontSize[1];

    setFontSizeCanvas(fontSizeIncremented);
    setLineHeight(fontSizeIncremented);

    canvasComponentRef.current?.reDraw(
      fontSizeIncremented,
      fontSizeIncremented,
      "",
      1,
      fontSizeIncremented * arrLimitTextLabel[0].maxRows + 10,
      false,
      insertDetail
    );
  };

  const getRotate = () => {
    const rotate = props.insertDetail.rotate ?? 0;

    if (rotate == 90) {
      return 270;
    }
    if (rotate == 180) {
      return 180;
    }
    if (rotate == 270) {
      return 90;
    }

    return 0;
  };

  const getNewWidth = (insertDetail: IInsertDetail, newRotate?: number) => {
    const textFieldProps = LimitChar.get(props.designCode)!;

    if (newRotate == null && (insertDetail.rotate == null || insertDetail.rotate == 0)) {
      return props.textLabelSizes.canvas?.width;
    }

    const rotate = newRotate ?? insertDetail.rotate!;

    if (props.isRocker) {
      return isHorizontal(rotate)
        ? textFieldProps.horizontal[props.tileName!].rocker.canvas.width
        : textFieldProps.vertical[props.tileName!].rocker.canvas.width;
    }

    return isHorizontal(rotate)
      ? textFieldProps.horizontal[props.tileName!].canvas.width
      : textFieldProps.vertical[props.tileName!].canvas.width;
  };

  const getIconSize = () => {
    const elementSize: IElementSize = {
      width: iconRef.current?.offsetWidth!,
      height: iconRef.current?.offsetHeight!,
    };

    return elementSize;
  };

  const handleOnIconSelected = (): IDimension => {
    const dimension = getElementPosition(props.coverRef!, iconRef, getIconSize);
    return dimension!;
  };

  const handleTextInputChange = (textInputChangeArgs: IHandleTextInputChangeParams) => {
    const { value, startPosition, endPosition } = textInputChangeArgs;
    setTextAreaValue(value);
    startPosition && endPosition && canvasComponentRef.current?.handleOnKeyUp(value, startPosition, endPosition);
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "100%",
        }}
      >
        <div
          className="box-icon"
          onClick={(event) => handleOpenIconbox(event)}
          style={{
            fontFamily: `${theme.name == ABB ? "ABBvoice" : "BJEAverta"}`,
            transform: `rotate(${getRotate()}deg)`,
            width: `${getNewWidth(props.insertDetail, props.insertDetail.rotate!)}px`,
          }}
        >
          <IconButton
            className={`${
              props.tileDimension.isFunction
                ? showCanvas
                  ? `${theme.name}-border-icon-function-with-canvas`
                  : `${theme.name}-border-icon-function`
                : showCanvas
                ? `${theme.name}-border-icon-control-with-canvas`
                : `${theme.name}-border-icon-control`
            }`}
          >
            <TooltipDimension
              coverRef={props.coverRef!}
              elementRef={iconRef}
              iconRef={iconRef}
              styleProps={{
                width: iconDimension?.width! + "px",
                height: iconDimension?.height! + "px",
              }}
              enableDimensionLine={true}
              enableDimensionBorder={false}
              enableStartEndPositions={false}
              insertDetail={props.insertDetail}
              indexDetail={props.indexDetail}
              designSizes={designSizes}
            >
              <IconHandler
                // id={getGenericPropertyValue(props.insertDetail, props.indexDetail, "icon")}
                id={
                  props.insertDetail.items?.find(
                    (item) => item.type == EInsertDetailItemType.Icon && item.indexDetail == props.indexDetail
                  )?.iconId!
                }
                iconDimension={iconDimension?.width! + "px"}
                renderEmpty={false}
                insertColor={props.insertDetail.insertColor}
                isFunction={props.tileDimension.isFunction || false}
                indexDetail={props.indexDetail}
                cover={props.cover}
              />
            </TooltipDimension>
          </IconButton>

          <div
            style={{
              width: `${getNewWidth(props.insertDetail, props.insertDetail.rotate!)}px`,
            }}
          >
            <Canvas
              ref={canvasComponentRef}
              indexDetail={props.indexDetail}
              cover={props.cover}
              insertDetail={props.insertDetail}
              isRocker={props.isRocker}
              showCanvas={showCanvas}
              textAreaValue={textAreaValue}
              textAreaPreviewValue={textAreaPreviewValue}
              fontSizeCanvas={fontSizeCanvas}
              indexFontSize={indexFontSize}
              lineHeight={lineHeight}
              tileDimension={props.tileDimension}
              tileName={props.tileName!}
              designCode={props.designCode}
              textLabelSizes={props.textLabelSizes}
              setInsertDetail={props.setInsertDetail}
              setTextAreaValue={setTextAreaValue}
              setTextAreaPreviewValue={setTextAreaPreviewValue}
              setShowCanvas={setShowCanvas}
              setDisplayHighlightedWarning={setDisplayHighlightedWarning}
              setCurrentCanvasValue={setCurrentCanvasValue}
              coverRef={props.coverRef}
            />
          </div>
        </div>
      </Grid>

      <IconDialogMain
        tileDimension={props.tileDimension}
        insertDetail={props.insertDetail}
        setInsertDetail={props.setInsertDetail}
        setIconDimension={setIconDimension}
        indexDetail={props.indexDetail}
        cover={props.cover}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        showCanvas={showCanvas}
        textAreaValue={textAreaValue}
        fontSizeCanvas={fontSizeCanvas}
        lineHeight={lineHeight}
        canvasComponentRef={canvasComponentRef}
        displayHighlightedWarning={displayHighlightedWarning}
        setOpen={setOpen}
        setShowCanvas={setShowCanvas}
        handleTextInputChange={handleTextInputChange}
        setTextAreaValue={setTextAreaValue}
        handleDecreaseFont={handleDecreaseFont}
        handleIncreaseFont={handleIncreaseFont}
        handleResetAll={handleResetAll}
        handleOnIconSelected={handleOnIconSelected}
        currentCanvasValue={currentCanvasValue}
        tileType={props.tileType}
        isFunction={props.tileDimension.isFunction ?? false}
        recentlyIcons={recentlyIcons as IIcon[]}
        setRecentlyIcons={setRecentlyIcons}
      />
    </>
  );
};

export default TileConfiguration;
