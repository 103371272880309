import { ChevronLeftOutlined as ChevronLeftOutlinedIcon } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExternalLayout from "../../../components/Layout/ExternalLayout";
import Spinner from "../../../components/Spinner/Spinner";
import { ETilesByInsertType } from "../../../enum/EInsertType";
import { useStoreActions, useStoreState } from "../../../hooks";
import { IProjectSummaryDto } from "../../../interfaces/DTO/IProjectSummaryDto";
import { IInsertColor } from "../../../interfaces/IInsertColor";
import { EInsertDetailItemType, IInsertDetail } from "../../../interfaces/IInsertDetail";
import { IUserDesignData } from "../../../interfaces/IUserDesignData";
import { executeWithDebounce } from "../../../utils/debounce";
import Cover from "../Components/Cover/Cover";
import CoverActions from "../Components/CoverActions/CoverActions";
import Frame from "../Components/Frame/Frame";
import HelpCustomizationButton from "../FrameConfiguration/HelpCustomizationButton";
import { ReactComponent as AddIcon } from "../Icons/AddIcon.svg";
import CoverControlButton from "./CoverControlButton";
import EmptyCoverDialog, { emptyCoverDialogRef } from "./EmptyCoverDialog/EmptyCoverDialog";
import InsertColorContainer from "./InsertColorContainer";
import "./InsertConfiguration.css";
import GridLineSwitch from "./LayoutConfiguration/GridLineSwitch";
import PreviewLiveButton from "./PreviewLive/PreviewLiveButton";
import TileTypeSelect from "./TileTypeSelect";
import { ICanvasForwardProps } from "./Tiles/Canvas";
import LayoutConfiguration from "./Tiles/LayoutConfiguration";
import CoverItemsFunctions from "./Tiles/utils-cover-items";
import CoverItemsRefsFunctions from "./Tiles/utils-cover-items-refs";
import { useTranslation } from "react-i18next";
import useAppTitle from "../../../hooks/useAppTitle";
import EditDialog, { EditButton, editDialogRef } from "../DesignSummary/Edit/EditDialog";
import FavoriteDesignsButton from "./FavoriteDesignsButton";
import FavoriteDesignsDialog, { favoriteDesignsDialogRef } from "./FavoriteDesignsDialog/FavoriteDesignsDialog";
import { IProductDesignResponse } from "../../../interfaces/Project/Response/ProductDesignResponse";

type InsertConfigurationState = {
  selectedCoverNumber?: number;
  isDesignSelected?: boolean;
};

export const tileRefs: any[] = [];

const InsertConfiguration: FC<{}> = (props) => {
  const { t } = useTranslation();
  const navigate: any = useNavigate();
  const location = useLocation();
  const state = location.state as InsertConfigurationState | null;
  const appTitle = useAppTitle();
  const { designSizes, insertTypes, frameFolds, projectsSummary, fetchingCover } = useStoreState(
    (state) => state.designs.data
  );

  const { userDesignData, currentProjectId } = useStoreState((state) => state.designs.configuration);

  const {
    thunkGetDesignSizes,
    setUserDesignData,
    setFetchingCover,
    thunkAutoSave,
    thunkSetCurrentPathName,
    setSelectedCoverNumberGlobal,
  } = useStoreActions((actions) => actions.designs);

  const [selectedCoverNumber, setSelectedCoverNumber] = useState(state?.selectedCoverNumber ?? 0);
  const [currentProject, setCurrentProject] = useState<IProjectSummaryDto>(
    projectsSummary.find((x) => x.id === currentProjectId)!
  );
  const [projectName, setProjectName] = useState<string>("");
  const [chosenFavoriteDesignStyles, setChosenFavoriteDesignStyles] = useState<IProductDesignResponse | null>(null);
  let switchesNumber = userDesignData?.numberOfSwitches;

  useEffect(() => {
    if (state == null || userDesignData == null) {
      navigate("/dashboard", { replace: true });
    } else {
      const newCurrentProject = projectsSummary?.find((project) => project.id === currentProjectId)!;
      setCurrentProject(newCurrentProject);
      setProjectName(newCurrentProject?.name);
      Object.keys(designSizes).length === 0 &&
        userDesignData?.design !== undefined &&
        thunkGetDesignSizes(userDesignData?.design?.id);
    }
  }, []);

  useEffect(() => {
    if (userDesignData && chosenFavoriteDesignStyles) {
      const chosenFavoriteInsert = chosenFavoriteDesignStyles.insertDetails[0];

      const itemsWithoutId = chosenFavoriteInsert.items?.map(({ id, ...rest }) => rest);

      const updatedInsertDetails = [...userDesignData.userCustomDesign.insertDetails];
      updatedInsertDetails[selectedCoverNumber] = {
        ...updatedInsertDetails[selectedCoverNumber],
        hasGridLine: chosenFavoriteInsert.hasGridLine,
        insertColor: chosenFavoriteInsert.insertColor,
        insertType: chosenFavoriteInsert.insertType,
        isFlipped: chosenFavoriteInsert.isFlipped,
        items: itemsWithoutId,
      };

      const newValues = {
        ...userDesignData,
        userCustomDesign: {
          ...userDesignData.userCustomDesign,
          coverImage: "",
          designId: chosenFavoriteDesignStyles.designId,
          frameColor: chosenFavoriteDesignStyles.frameColor,
          insertDetails: updatedInsertDetails,
        },
      };

      setUserDesignData(newValues);
    }
  }, [chosenFavoriteDesignStyles]);

  const handleChangeCoverColor = async (cColor: IInsertColor) => {
    const detail = userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber];
    const items = userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber].items ?? [];
    const prevPrimaryColor = detail?.insertColor?.primaryPrintColor?.ralColorCode;
    const prevSecondaryColor = detail?.insertColor?.secondaryPrintColor?.ralColorCode;

    const newPrimaryColor = cColor.primaryPrintColor?.ralColorCode;
    const newSecondaryColor = cColor.secondaryPrintColor?.ralColorCode;

    const newItems = items.map((item) => {
      let color = item.color;

      if (item.color === prevPrimaryColor) {
        color = newPrimaryColor!;
      } else if (item.color === prevSecondaryColor) {
        color = newSecondaryColor!;
      }

      if (item.type == EInsertDetailItemType.SeparationLine || item.type == EInsertDetailItemType.Slider) {
        return {
          ...item,
          color: color,
          lineColor: item.color === prevPrimaryColor ? newPrimaryColor : newSecondaryColor,
        };
      }

      return { ...item, color: color };
    });

    const newInsertDetails = userDesignData?.userCustomDesign.insertDetails.map((detail, index) => {
      if (selectedCoverNumber === index) {
        let newDetail: IInsertDetail = { ...detail, insertColor: cColor };

        const tileRefsSelectedCover = tileRefs[selectedCoverNumber] as React.RefObject<ICanvasForwardProps>[];

        for (let property in tileRefsSelectedCover) {
          newDetail = tileRefsSelectedCover[property].current?.refresh(detail.rotate, newDetail);
        }

        return { ...newDetail, items: newItems };
      }

      return detail;
    });

    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            insertDetails: newInsertDetails!,
          },
        },
      },
    };

    console.log(newValues);
    setUserDesignData(newValues);
  };

  const handleChangeTileType = async (e: any) => {
    const insertType = insertTypes.find((it) => it.id == e.target.value)!;
    const hasGridLine = userDesignData?.userCustomDesign.insertDetails[0].hasGridLine;

    const newInsertDetails = userDesignData?.userCustomDesign.insertDetails.map((detail, index) => {
      if (selectedCoverNumber == index) {
        const newDetail = { ...detail, insertType: insertType, hasGridLine: hasGridLine };

        let excludedFields = ["id", "order", "insertColor", "insertType", "rotate", "coverDesignId", "hasGridLine"];

        if (!insertType.allowRotate) excludedFields = excludedFields.filter((x) => x !== "rotate");

        //clear the props, when insert type is changed
        Object.keys(newDetail)
          .filter((key) => !excludedFields.includes(key))
          .forEach((key) => {
            (newDetail as any)[key] = null;

            if (key == "rotate") {
              (newDetail as any)[key] = 0;
            }

            if (key == "isFlipped") {
              (newDetail as any)[key] = false;
            }

            if (key == "items") {
              (newDetail as any)[key] = [];
            }
          });

        return newDetail;
      }

      return detail;
    });

    CoverItemsRefsFunctions.removeAllRefsByCover(selectedCoverNumber);
    CoverItemsFunctions.removeAllByInsertDetailId(
      userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber].id!
    );

    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            insertDetails: newInsertDetails!,
          },
        },
      },
    };

    setUserDesignData(newValues);
  };

  const handlePreviousCover = () => {
    if (selectedCoverNumber > 0) {
      const newwSelectedCoverNumber = selectedCoverNumber - 1;
      setSelectedCoverNumber(newwSelectedCoverNumber);
      setSelectedCoverNumberGlobal(newwSelectedCoverNumber);
    }
  };

  const handleNextCover = () => {
    if (selectedCoverNumber < userDesignData?.numberOfSwitches! - 1) {
      const newwSelectedCoverNumber = selectedCoverNumber + 1;
      setSelectedCoverNumber(newwSelectedCoverNumber);
      setSelectedCoverNumberGlobal(newwSelectedCoverNumber);
    }
  };

  const handleAddCover = () => {
    setFetchingCover(true);

    const newNumberOfSwitches: number = userDesignData?.numberOfSwitches! + 1;
    const newFrameFold = frameFolds.find((ff) => ff.frameCode === newNumberOfSwitches.toString()!);
    const newInsertDetails = userDesignData?.userCustomDesign.insertDetails;

    const separationLinesItems = newInsertDetails![newInsertDetails!.length - 1].items?.filter((item) =>
      [EInsertDetailItemType.SeparationLine, EInsertDetailItemType.Slider].includes(item.type)
    )
      ? newInsertDetails![newInsertDetails!.length - 1].items
          ?.filter((item) => [EInsertDetailItemType.SeparationLine, EInsertDetailItemType.Slider].includes(item.type))
          .map((item) => {
            delete item.id;
            return item;
          })
      : [];

    const currentInsertType = newInsertDetails![newInsertDetails?.length! - 1].insertType;
    const currentInsertColor = newInsertDetails![newInsertDetails?.length! - 1].insertColor!;

    const insertDetail: IInsertDetail = {
      insertType: currentInsertType ?? insertTypes.find((x) => x.name === "Button"),
      insertColor: currentInsertColor,
      order: newNumberOfSwitches,
      rotate: 0,
      hasGridLine: newInsertDetails![0].hasGridLine,
      items: separationLinesItems,
    };

    newInsertDetails?.push(insertDetail);

    const newValues: IUserDesignData = {
      ...userDesignData!,
      ...{
        numberOfSwitches: newNumberOfSwitches,
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            frameFold: newFrameFold,
            insertDetails: newInsertDetails!,
          },
        },
      },
    };

    switchesNumber = newNumberOfSwitches;
    setSelectedCoverNumber(newNumberOfSwitches - 1);
    setSelectedCoverNumberGlobal(newNumberOfSwitches - 1);
    setUserDesignData(newValues);

    executeWithDebounce(thunkAutoSave, newValues);
  };

  const hasInvalidCovers = () => {
    let hasInvalid = false;

    // //check if the TILE has at least one icon or text
    // userDesignData?.userCustomDesign.insertDetails.forEach((detail, index) => {
    //   let tiles = ETilesByInsertType.get(detail.insertType?.name!) || 0;

    //   for (let index = 1; index <= tiles; index++) {
    //     const items = detail.items.filter(
    //       (item) =>
    //         (item.type == EInsertDetailItemType.Icon && item.indexDetail == index.toString()) ||
    //         (item.type == EInsertDetailItemType.Text && item.indexDetail == index.toString())
    //     );

    //     if (items.length < 1) {
    //       hasInvalid = true;
    //     }
    //   }
    // });

    // //check if the COVER has at least one icon or text
    userDesignData?.userCustomDesign.insertDetails.forEach((detail, index) => {
      let tiles = ETilesByInsertType.get(detail.insertType?.name!) || 0;

      const items = detail.items.filter((item) => {
        return (
          item.type === EInsertDetailItemType.Icon ||
          (item.type === EInsertDetailItemType.Text && (item.textValue?.trim().length || 0) > 0)
        );
      });

      if (items.length < 1) {
        hasInvalid = true;
      }
    });

    return hasInvalid;
  };

  const handleGoToSummary = async () => {
    if (hasInvalidCovers()) {
      emptyCoverDialogRef.current?.open();
      return;
    }

    await thunkSetCurrentPathName("/design-preview");

    navigate("/design-preview", {
      state: {
        isDesignSelected: true,
      },
    });
  };

  const handleOpenFavoriteDesignsTable = () => {
    favoriteDesignsDialogRef.current?.open();
  };

  const handleBackToFrameConfiguration = async () => {
    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            pathname: "/frame-configuration",
          },
        },
      },
    };

    setUserDesignData(newValues);
    await thunkAutoSave(newValues);

    navigate("/frame-configuration", {
      state: { isDesignSelected: true },
    });
  };

  const setInsertDetailFn = async (detailEdit: IInsertDetail) => {
    const newInsertDetails = userDesignData?.userCustomDesign.insertDetails.map((detail, index) => {
      if (detail.order === detailEdit.order) {
        return {
          ...detail,
          ...detailEdit,
        };
      }

      return detail;
    });

    const newValues = {
      ...userDesignData!,
      ...{
        numberOfSwitches: switchesNumber,
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            insertDetails: newInsertDetails!,
          },
        },
      },
    };

    setUserDesignData(newValues);
  };

  const getPositionAddCoverButton = () => {
    if (userDesignData?.userCustomDesign.isHorizontal)
      return selectedCoverNumber + 1 == userDesignData?.userCustomDesign?.insertDetails?.length ? "75%" : "";

    return selectedCoverNumber + 1 == userDesignData?.userCustomDesign?.insertDetails?.length ? "45%" : "";
  };

  const getDisplayAddCoverButton = () => {
    return selectedCoverNumber + 1 == userDesignData?.userCustomDesign?.insertDetails?.length ? "" : "none";
  };

  return (
    <>
      <ExternalLayout
        showNavbarCenteredText={false}
        showNavbarLinks={true}
        showPageHeader={false}
        pageHeaderTextTranslationKey="welcomeToBuschJaegerCustomisationCenter"
        navbarLogoLink="/"
        navbarMainTitle={appTitle}
        navbarBackGroundColor="#fff"
        bodyBackGroundColor="#fff"
      >
        {/* <button onClick={() => console.log("canvas refs", coverItemsRefs)}>coverRefs</button>
        <button onClick={() => console.log("cover items", items)}>coverItems</button> */}

        {(userDesignData == null && <Spinner />) || (
          <>
            <div className="insertConfiguration">
              <div className="insertConfiguration__main">
                <div className="insertConfiguration__headerContainer">
                  <div className="insertConfiguration__headerContainerButtons">
                    <Button
                      className="insertConfiguration__backToSwitchSelectionBtn"
                      variant="text"
                      onClick={handleBackToFrameConfiguration}
                      startIcon={<ChevronLeftOutlinedIcon sx={{ width: "28px", height: "28px" }} />}
                    >
                      <span>{t("insert_configuration.button.back_to_general_configuration")}</span>
                    </Button>
                    <Typography
                      variant="body1"
                      fontSize="16px"
                      fontWeight={400}
                      color="#696969"
                    >
                      {t("insert_configuration.label.project_name")}
                      <span style={{ color: "#000" }}> {projectName}</span>
                      <EditButton editDialogRef={editDialogRef} />
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            <div className="insertConfiguration__mainContainer">
              <div
                className={
                  userDesignData?.userCustomDesign.isHorizontal
                    ? "insertConfiguration__mainPreview--horizontal"
                    : "insertConfiguration__mainPreview--vertical"
                }
              >
                <div className="insertConfiguration__mainPreviewTitle">
                  <Typography
                    variant="body1"
                    fontSize="16px"
                    fontWeight={400}
                  >
                    {t("insert_configuration.label.your_nem_switch_is_almost_ready")}
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="24px"
                    fontWeight={500}
                  >
                    {t("insert_configuration.label.edit_layout_add_texts_or_icons")}
                  </Typography>
                </div>

                <div
                  style={{
                    width: "100%",
                    transform: `${!userDesignData?.userCustomDesign.isHorizontal ? "rotate(90deg)" : "rotate(0deg)"}`,
                    marginTop: `${!userDesignData?.userCustomDesign.isHorizontal ? "240px" : "0px"}`,
                  }}
                >
                  <div className="frame-shadow-container">
                    <Frame
                      design={userDesignData?.design!}
                      frameColor={userDesignData?.userCustomDesign.frameColor!}
                      isHorizontal={userDesignData?.userCustomDesign.isHorizontal!}
                      numberOfSwitches={userDesignData?.numberOfSwitches!}
                      enableSelection={true}
                      enableTransition={true}
                      selectedCoverNumber={selectedCoverNumber}
                      onNextCallback={handleNextCover}
                      onPreviousCallback={handlePreviousCover}
                    >
                      {userDesignData?.userCustomDesign.insertDetails?.map((detail, coverIndex) => (
                        <Cover
                          onClick={() => {
                            setSelectedCoverNumber(coverIndex);
                            setSelectedCoverNumberGlobal(coverIndex);
                          }}
                          key={`cover-${coverIndex}`}
                          cover={coverIndex}
                          insertDetail={detail}
                          showMessageOnImageNotFound={true}
                          designCode={userDesignData?.design?.code!}
                          isHorizontal={userDesignData.userCustomDesign.isHorizontal!}
                        >
                          {
                            <LayoutConfiguration
                              key={`layout-configuration-${coverIndex}`}
                              cover={coverIndex}
                              insertDetail={detail}
                              designCode={userDesignData.design?.code!}
                              isHorizontal={userDesignData.userCustomDesign.isHorizontal!}
                              setInsertDetail={setInsertDetailFn}
                              insertType={detail.insertType?.name}
                              isPreview={coverIndex != selectedCoverNumber}
                              canManageRef={true}
                            />
                          }
                        </Cover>
                      ))}
                    </Frame>
                    {userDesignData?.numberOfSwitches! <
                      userDesignData?.userCustomDesign.frameColor?.numberOfFolds! && (
                      <Button
                        onClick={handleAddCover}
                        disabled={fetchingCover}
                        sx={{
                          transform: !userDesignData?.userCustomDesign.isHorizontal ? "rotate(270deg)" : "",
                          marginLeft: getPositionAddCoverButton(),
                          width: "100px",
                          height: "100px",
                          alignSelf: "center",
                          textTransform: "unset",
                          display: getDisplayAddCoverButton(),
                          flexDirection: "column",
                          position: "absolute",
                        }}
                        variant="text"
                      >
                        <AddIcon style={{ fill: "#696969" }} />
                        <span
                          style={{
                            marginTop: "13px",
                            color: "#696969",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          {t("insert_configuration.label.add_cover")}
                        </span>
                      </Button>
                    )}
                  </div>

                  <CoverActions
                    selectedCoverNumber={selectedCoverNumber}
                    setSelectedCoverNumber={setSelectedCoverNumber}
                    setInsertDetail={setInsertDetailFn}
                  />

                  <PreviewLiveButton
                    productDesignId={userDesignData?.userCustomDesign.id!}
                    isHorizontal={userDesignData?.userCustomDesign.isHorizontal}
                  />
                </div>
              </div>

              {
                <div className="insertConfiguration__mainParameters">
                  <div className="insertConfiguration__mainParametersTitle">
                    <Typography
                      variant="h4"
                      fontSize="32px"
                      fontWeight={700}
                    >
                      {userDesignData?.numberOfSwitches === 1
                        ? t("insert_configuration.label.editing_cover")
                        : `${t("insert_configuration.label.editing_cover_number")}${selectedCoverNumber + 1}`}
                    </Typography>
                  </div>

                  <div className="insertConfiguration__mainParametersDivider"></div>

                  <div className="insertConfiguration__favDesignButtonContainer">
                    <FavoriteDesignsButton handleOpenFavoriteDesignsTable={handleOpenFavoriteDesignsTable} />
                  </div>

                  <div className="insertConfiguration__mainParameterInsertColor">
                    <Typography
                      variant="h4"
                      fontSize="21px"
                      fontWeight={700}
                    >
                      {t("insert_configuration.label.cover_color")}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="16px"
                      fontWeight={400}
                    >
                      {t("insert_configuration.label.you_can_choose_your_cover_color")}
                    </Typography>

                    <InsertColorContainer
                      selectedCoverNumber={selectedCoverNumber}
                      handleChangeCoverColor={handleChangeCoverColor}
                    />
                  </div>

                  <div className="insertConfiguration__mainParameterSwitches">
                    <Typography
                      variant="h4"
                      fontSize="21px"
                      fontWeight={700}
                    >
                      {t("insert_configuration.label.layout_configuration")}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="16px"
                      fontWeight={400}
                    >
                      {t("insert_configuration.label.select_tiles_layout_for_your_cover")}
                    </Typography>

                    <div className="insertConfiguration__mainParameterSwitchesLayout">
                      <TileTypeSelect
                        selectedCoverNumber={selectedCoverNumber}
                        handleChangeTileType={handleChangeTileType}
                        insertTypes={insertTypes}
                      />
                    </div>
                  </div>

                  <GridLineSwitch
                    allow={
                      userDesignData?.userCustomDesign?.insertDetails[selectedCoverNumber]?.insertType?.allowGridLine!
                    }
                    enable={userDesignData?.userCustomDesign?.insertDetails[selectedCoverNumber]?.hasGridLine!}
                  />

                  <div className="insertConfiguration__mainParametersDivider"></div>

                  <div className="insertConfiguration__mainParametersNextButton">
                    <CoverControlButton
                      selectedCoverNumber={selectedCoverNumber}
                      numberOfSwitches={userDesignData?.numberOfSwitches!}
                      handlePreviousCover={handlePreviousCover}
                      handleNextCover={handleNextCover}
                      handleGoToSummary={handleGoToSummary}
                    />
                  </div>

                  {selectedCoverNumber < userDesignData?.numberOfSwitches! - 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "32px",
                      }}
                      className="insertConfiguration__goToSummaryShortCutBtn"
                    >
                      <Typography
                        variant="body1"
                        fontSize="16px"
                        fontWeight={400}
                      >
                        {t("insert_configuration.label.are_you_done_editing_switches")}
                      </Typography>
                      <Button
                        variant="text"
                        onClick={handleGoToSummary}
                        sx={{
                          whiteSpace: "normal",
                          overflow: "hidden",
                          width: "100%",
                          fontWeight: "700 !important",
                        }}
                      >
                        {t("insert_configuration.button.go_to_summary")}
                      </Button>
                    </Box>
                  )}
                </div>
              }
            </div>

            <div className="insertConfiguration__needHelpDiv">
              <div className="insertConfiguration__needHelpDivInside">
                <HelpCustomizationButton />
              </div>
            </div>

            <EmptyCoverDialog ref={emptyCoverDialogRef} />
            <FavoriteDesignsDialog
              ref={favoriteDesignsDialogRef}
              setChosenFavoriteDesignStyles={setChosenFavoriteDesignStyles}
            />
            <EditDialog
              ref={editDialogRef}
              name={projectName}
              projectId={currentProjectId!}
              setProjectName={setProjectName}
            />
          </>
        )}
      </ExternalLayout>
    </>
  );
};

export default InsertConfiguration;
