import IconButton from "@mui/material/IconButton";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { FC, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "../../hooks";
import { Status } from "../../models/status";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { IProjectCreateRequest } from "../../interfaces/Project/Request/IProjectCreateRequest";
import { IProjectSummaryDto } from "../../interfaces/DTO/IProjectSummaryDto";
import { IProductDesignResponse } from "../../interfaces/Project/Response/ProductDesignResponse";
import { ECompany } from "../../enum/ECompany";
import DotsMenu from "./DotsMenu";
import { LoadingButton } from "@mui/lab";
import { IResult } from "../../interfaces/IResult";
import EditDialog, { editDialogRef } from "../../pages/Configurator/DesignSummary/Edit/EditDialog";
import ProductDesignService from "../../services/ProductDesignService";

interface IThreeDotsMenuProps {
  data: IProjectSummaryDto;
  enableNameEdit?: boolean;
}

const ThreeDotsMenu: FC<IThreeDotsMenuProps> = ({ data, enableNameEdit = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const { currentUser } = useStoreState((state) => state.auth);

  const { thunkOpenSnackBar, thunkOpenConfirm, thunkCloseConfirm } = useStoreActions((actions) => actions.app);

  const {
    thunkDeleteProject,
    setCurrentProjectId,
    thunkCreateProject,
    setCurrentDesignId,
    setUserDesignDataFromProductDesignResponse,
    thunkGetProductDesignDataByProjectId,
    thunkAddCoverSet,
    thunkAddCopiedCoverSet,
  } = useStoreActions((actions) => actions.designs);

  const [, setDeleteOpenAlertDialog] = useState<boolean>(false);
  const [openDuplicateAlertDialog, setDuplicateAlertDialog] = useState<boolean>(false);
  const [duplicateProjectName, setDuplicateProjectName] = useState<string>("");
  const [projectToBeDuplicated, setProjectToBeDuplicated] = useState<IProjectSummaryDto | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState<string>("");

  const handleOpenEditProjectName = () => {
    setProjectName(data.name);
    editDialogRef.current?.open();
  };

  const handleDeleteProject = (project: IProjectSummaryDto) => {
    thunkOpenConfirm({
      title: t("DeleteProject_ConfirmDialog_Title"),
      message: t("DeleteProject_ConfirmDialog_Message"),
      onDismiss: {
        text: t("DeleteProject_ConfirmDialog_DismissButton_Text"),
      },
      onConfirm: {
        text: t("DeleteProject_ConfirmDialog_ConfirmButton_Text"),
        onClick: () => handleSubmitDelete(project),
      },
      useLoading: true,
      highlightCancel: true,
    });
  };

  const handleMoreOptionsClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAlertDialog = () => {
    setDeleteOpenAlertDialog(false);
    setDuplicateAlertDialog(false);
  };

  const handleEdit = async (id: string) => {
    var response = await thunkGetProductDesignDataByProjectId(id);

    if (response.status == Status.FAILED) {
      thunkOpenSnackBar({
        message: t("recent_projects.message_error.get_product_design_by_userId"),
        severity: "error",
      });
      return;
    }

    const productDesignsFromProject = response.data as IProductDesignResponse[];

    const prodDesign = productDesignsFromProject.find((p) => p.project?.id === id);

    setCurrentProjectId(id);

    setCurrentDesignId(prodDesign?.id ?? null);

    setUserDesignDataFromProductDesignResponse(prodDesign!);

    navigate(prodDesign?.pathname ?? "/", {
      state: { isNewProject: false, isDesignSelected: prodDesign !== undefined },
    });
  };

  const handleDuplicate = (project: IProjectSummaryDto) => {
    setProjectToBeDuplicated(project);
    setDuplicateAlertDialog(true);
  };

  const handleSubmitDelete = async (project: IProjectSummaryDto) => {
    const res = await thunkDeleteProject(project.id);

    if (res === Status.COMPLETED) {
      thunkOpenSnackBar({
        message: t("DeleteProject_ConfirmDialog_SuccessMessage").replace("[PROJECT_NAME]", project.name),
        severity: "success",
      });

      thunkCloseConfirm();
      return;
    }

    thunkOpenSnackBar({
      message: t(res.message),
      severity: "error",
    });

    thunkCloseConfirm();
  };

  const handleSubmitDuplicateAlertDialog = async () => {
    if (projectToBeDuplicated != null) {
      const duplicatedProjectName = duplicateProjectName.trim();
      if (duplicatedProjectName.length === 0) {
        thunkOpenSnackBar({
          message: t("DuplicateProjectDialog_EnterValidProjectName_Message"),
          severity: "warning",
        });

        return;
      }
      if (duplicatedProjectName.length > 50) {
        thunkOpenSnackBar({
          message: t("DuplicateProjectDialog_MaxLengthProjectName_Message"),
          severity: "warning",
        });

        return;
      }

      setLoading(true);

      const projectToCopy: IResult<IProductDesignResponse[]> = await thunkGetProductDesignDataByProjectId(data.id);

      if (projectToCopy.status === Status.FAILED) {
        thunkOpenSnackBar({
          message: t(projectToCopy.message),
          severity: "error",
        });
        setLoading(false);
        return;
      }

      const payload: IProjectCreateRequest = {
        projectName: duplicateProjectName,
        company: process.env.REACT_APP_THEME == "bje" ? ECompany.BJE : ECompany.ABB,
      };
      const res = await thunkCreateProject(payload);

      if (res.status === Status.FAILED) {
        thunkOpenSnackBar({
          message: t(res.message),
          severity: "error",
        });
        setLoading(false);
        return;
      }

      const productDesignsFromProject = projectToCopy.data as IProductDesignResponse[];

      await ProductDesignService.addNewProductDesign({
        projectId: res.id!,
        company: payload.company,
        countryId: currentUser?.countryId!,
        design: productDesignsFromProject[0].design?.name!,
      });

      const newProjectId = res.id;

      const getProjectResponse = await thunkGetProductDesignDataByProjectId(newProjectId!);
      if (getProjectResponse.status === Status.FAILED) {
        thunkOpenSnackBar({
          message: t(getProjectResponse.message),
          severity: "error",
        });
        setLoading(false);
        return;
      }

      //copy of the project has one productDesign by default, in this loop the rest of needed productDesigns are added
      for (let i = 0; i < productDesignsFromProject.length - 1; i++) {
        const addCoverResponse = await thunkAddCoverSet();
        if (addCoverResponse.status === Status.FAILED) {
          thunkOpenSnackBar({
            message: t(addCoverResponse.message),
            severity: "error",
          });
          setLoading(false);
          return;
        }
      }

      const copyResponse = await thunkAddCopiedCoverSet(productDesignsFromProject);
      if (copyResponse.status === Status.FAILED) {
        thunkOpenSnackBar({
          message: t(copyResponse.message),
          severity: "error",
        });
        setLoading(false);
        return;
      }

      thunkOpenSnackBar({
        message: t("DuplicateProjectDialog_Success_Message").replace("[PROJECT_NAME]", duplicateProjectName),
        severity: "success",
      });

      setDuplicateAlertDialog(false);
      thunkCloseConfirm();
      setLoading(false);
    }
  };

  return (
    <>
      <IconButton onClick={(e) => handleMoreOptionsClick(e)}>
        <MoreVertOutlinedIcon sx={{ color: "#000" }} />
      </IconButton>

      <DotsMenu
        data={data}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleDeleteProject={handleDeleteProject}
        handleDuplicate={handleDuplicate}
        handleEdit={handleEdit}
        enableNameEdit={enableNameEdit}
        handleOpenEditProjectName={handleOpenEditProjectName}
      />

      <Dialog
        open={openDuplicateAlertDialog}
        onClose={handleCloseAlertDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "480px",
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{t("duplicateProject")}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("DuplicateProjectDialog_Title")}</DialogContentText>
          <TextField
            inputProps={{
              style: {
                padding: 5,
              },
            }}
            sx={{
              margin: "20px 0 10px 0",
              width: "100%",
            }}
            disabled={isLoading}
            value={duplicateProjectName}
            onChange={(e) => setDuplicateProjectName(e.target.value)}
            helperText={
              duplicateProjectName.trim().length > 50 ? t("DuplicateProjectDialog_InputProjectName_HelperText") : ""
            }
            error={duplicateProjectName.trim().length > 50 ? true : false}
          />
        </DialogContent>

        <DialogActions style={{ marginRight: "15px" }}>
          <Button
            variant="text"
            onClick={handleCloseAlertDialog}
          >
            {t("cancel")}
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={handleSubmitDuplicateAlertDialog}
            autoFocus
            disabled={duplicateProjectName.trim().length > 50 || duplicateProjectName.trim().length === 0}
          >
            {t("duplicate")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <EditDialog
        ref={editDialogRef}
        name={projectName}
        projectId={data.id}
        setProjectName={setProjectName}
      />
    </>
  );
};

export default ThreeDotsMenu;
