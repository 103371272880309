import { useState, Dispatch, SetStateAction } from "react";
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { IProjectSummaryDto } from "../../../../interfaces/DTO/IProjectSummaryDto";
import { useTranslation } from "react-i18next";
import { EProjectStatusDescription } from "../../../../enum/EProjectStatus";
import "../ProjectSummary.css";
import { useStoreActions } from "../../../../hooks";
import { Status } from "../../../../models/status";
import { IProductDesignSummaryResponse } from "../../../../interfaces/Project/Response/ProductDesignSummaryResponse";
import { IProductDesignSummaryDto } from "../../../../interfaces/DTO/IProductDesignSummaryDto";
import { Card } from "../Card/Card";

export function Row(props: {
  row: IProjectSummaryDto;
  projects: IProductDesignSummaryResponse[];
  productDesignSummary: IProductDesignSummaryDto[];
  setCoverCost: Dispatch<SetStateAction<number>>;
  refeshScreen: () => void;
}) {
  const { row, projects, setCoverCost, productDesignSummary, refeshScreen } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { thunkOpenSnackBar, thunkOpenConfirm, thunkCloseConfirm } = useStoreActions((actions) => actions.app);
  const { thunkDeleteProject } = useStoreActions((actions) => actions.designs);
  const handleDeleteProject = (project: IProjectSummaryDto) => {
    thunkOpenConfirm({
      title: t("DeleteProject_ConfirmDialog_Title"),
      message: t("DeleteProject_ConfirmDialog_Message"),
      onDismiss: {
        text: t("DeleteProject_ConfirmDialog_DismissButton_Text"),
      },
      onConfirm: {
        text: t("DeleteProject_ConfirmDialog_ConfirmButton_Text"),
        onClick: () => handleSubmitDelete(project),
      },
      useLoading: true,
    });
  };
  const handleSubmitDelete = async (project: IProjectSummaryDto) => {
    const res = await thunkDeleteProject(project.id);

    if (res === Status.COMPLETED) {
      thunkOpenSnackBar({
        message: t("DeleteProject_ConfirmDialog_SuccessMessage").replace("[PROJECT_NAME]", project.name),
        severity: "success",
      });
      thunkCloseConfirm();
      return;
    }

    thunkOpenSnackBar({
      message: t(res.message),
      severity: "error",
    });

    thunkCloseConfirm();
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" }, backgroundColor: "#fafafa", height: "125px" }}>
        <TableCell
          component="th"
          scope="row"
          className="summaryTable_Cell_text details"
        >
          <IconButton
            aria-label="expand row"
            sx={{ height: 16, width: 16 }}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon sx={{ color: "black" }} /> : <KeyboardArrowDownIcon sx={{ color: "black" }} />}
          </IconButton>
          <Typography
            component="div"
            sx={{ display: "inline-flex", paddingLeft: "16px" }}
            fontWeight={700}
          >
            {" "}
            {row.name}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          className="summaryTable_Cell_text"
        >
          {row.productDesignsQuantity}
        </TableCell>
        <TableCell
          align="left"
          className="summaryTable_Cell_text"
        >
          {t(`${EProjectStatusDescription.get(row.status)}`)}
        </TableCell>
        <TableCell
          align="center"
          className="summaryTable_Cell_text"
        >
          <IconButton onClick={() => handleDeleteProject(row)}>
            <DeleteIcon></DeleteIcon>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ padding: 0 }}
          colSpan={6}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box>
              <Table>
                <TableBody>
                  <Card
                    projects={projects}
                    setCoverCost={setCoverCost}
                    productDesignSummary={productDesignSummary}
                    refeshScreen={refeshScreen}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
