import { AxiosResponse } from "axios";
import axiosInstance from "../config/axios";
import { IProject } from "../interfaces/IProject";
import { IProjectCreateRequest } from "../interfaces/Project/Request/IProjectCreateRequest";
import { IUpdateProject } from "../interfaces/Project/Request/IUpdateProject";
import { IProjectSummaryDto } from "../interfaces/DTO/IProjectSummaryDto";
import { IProjectDto } from "../interfaces/DTO/IProjectDto";
import { ECompany } from "../enum/ECompany";
import { IProductDesignResponse } from "../interfaces/Project/Response/ProductDesignResponse";
import { IProjectCreateWithFavRequest } from "../interfaces/Project/Request/IProjectCreateWithFavRequest";
import { IProductDesign } from "../interfaces/IProductDesign";

const responseBody = (response: AxiosResponse) => response;

const requests = {
  get: (url: string, options?: {}) => axiosInstance.get(url, options).then(responseBody),
  post: (url: string, body: {}, options: {}) => axiosInstance.post(url, body, options).then(responseBody),
  delete: (url: string) => axiosInstance.delete(url).then(responseBody),
};

const company = process.env.REACT_APP_THEME == "bje" ? ECompany.BJE : ECompany.ABB;

const ProjectService = {
  getSummaries: () => {
    return axiosInstance.get<IProjectSummaryDto[]>(`project/summaries?company=${company}`);
  },
  ExportXLSX: (projectId: string, languageCode: string) => {
    return axiosInstance.get(`project/${projectId}/${languageCode}/export/xlsx`, {
      params: { projectId },
      headers: { "Content-Type": "blob" },
      responseType: "arraybuffer",
    });
  },
  ExportPDF: (projectsId: string[], currentLanguageCode: string) => {
    return axiosInstance.post(`project/company/${company}/export/pdf/${currentLanguageCode}`, projectsId, {
      headers: { "Content-Type": "application/json" },
      responseType: "arraybuffer",
    });
  },
  getProjectsByUserId: (): Promise<AxiosResponse<IProject[]>> => {
    return requests.get(`project?company=${company}`);
  },
  createProject: (payload: IProjectCreateRequest): Promise<AxiosResponse<IProjectDto>> => {
    return requests.post("project", payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createProjectWithFavouriteId: (payload: IProjectCreateWithFavRequest): Promise<AxiosResponse<IProductDesign>> => {
    return requests.post("Project/createWithFavoriteDesign", payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update: (payload: IUpdateProject) => {
    return axiosInstance.put("project", payload);
  },
  deleteProject: (id: string): Promise<AxiosResponse> => requests.delete(`project/${id}`),
  exportProject: (info: IProductDesignResponse[], languageCode: string) => {
    info[0].company = process.env.REACT_APP_THEME == "bje" ? ECompany.BJE : ECompany.ABB;
    return axiosInstance.post(`project/${languageCode}/export`, info, {
      headers: { "Content-Type": "blob" },
      responseType: "arraybuffer",
    });
  },
};

export default ProjectService;
