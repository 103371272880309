import { Dialog, DialogContent, Grid, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { createRef, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from "react";
import { ReactSVG } from "react-svg";
import { useStoreActions } from "../../../../hooks";
import { IDialogHandle } from "../../../../interfaces/IDialogHandle";
import { IUpdateProject } from "../../../../interfaces/Project/Request/IUpdateProject";
import { Status } from "../../../../models/status";
import CloseIcon from "../../Icons/close.svg";
import "./edit-dialog.css";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../../App";
import SaveButton from "./SaveButton";
import CancelButton from "./CancelButton";
import EditIcon from "../../Icons/EditIcon.svg";

interface IEditDialogProps {
  name: string;
  projectId: string;
  setProjectName: React.Dispatch<React.SetStateAction<string>>;
}

export const editDialogRef = createRef<IDialogHandle>();

const EditDialog: ForwardRefRenderFunction<IDialogHandle, IEditDialogProps> = (props, ref) => {
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;

  const { thunkUpdateProject, thunkGetProjectsSummary } = useStoreActions((actions) => actions.designs);
  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);

  const [open, setOpen] = useState(false);
  const [projectName, setProjectName] = useState(props.name);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose,
    };
  });

  const handleEdit = async () => {
    setLoading(true);

    const updateProject: IUpdateProject = {
      id: props.projectId,
      name: projectName,
      isArchived: false,
    };

    const response = await thunkUpdateProject(updateProject);
    if (response === Status.COMPLETED) {
      props.setProjectName(updateProject.name);

      editDialogRef.current?.close();
      thunkGetProjectsSummary();
      thunkOpenSnackBar({
        message: t("design_summary_edit_dialog.update_project_name_response.message"),
        severity: "info",
      });
    } else {
      thunkOpenSnackBar({
        message: t(response.message),
        severity: "error",
      });
    }

    setLoading(false);
    handleClose();
  };

  const handleText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setProjectName(e.target.value);
    formIsDisabled(e.target.value);
  };

  const validateHelperText = () => {
    return projectName.trim().length > 50 ? t("design_summary_edit_dialog.helper_text.message") : "";
  };

  const validateError = () => {
    return projectName.trim().length > 50 ? true : false;
  };

  const formIsDisabled = (value: string) => {
    setIsDisabled(value.trim().length === 0);
  };

  return (
    <>
      <Dialog
        PaperProps={{
          sx: { position: "fixed", m: 0 },
        }}
        fullWidth={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "480px",
            },
          },
        }}
        onClose={handleClose}
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            editDialogRef.current?.close();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            width: 35,
            height: 35,
          }}
        >
          <ReactSVG
            onClick={() => {
              editDialogRef.current?.open();
            }}
            src={CloseIcon}
            beforeInjection={(svg) => {
              svg.setAttribute(
                "style",
                `width: 24px; height: 24px; fill: ${theme.icon.color}; padding: 0px; margin-top:5px`
              );
            }}
          />
        </IconButton>
        <DialogContent className="design-preview-dialog-main">
          <div className="design-preview-dialog-body">
            <Typography
              mb={2}
              variant="h2"
              fontSize="21px"
              fontWeight={700}
            >
              {t("DesignSummary_ModalTitle")}
            </Typography>
            <Typography variant="caption">{t("DesignSummary_LabelName")}</Typography>
            <TextField
              type="text"
              fullWidth
              defaultValue={props.name}
              onChange={(e) => handleText(e)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "4px",
                    border: "1px solid var(--primary-color-grey-30, #BABABA)",
                  },
                },
              }}
              inputProps={{
                maxLength: 50,
                style: {
                  height: "8px",
                },
              }}
            />
            {validateError() && <span className="design-preview-dialog-error">{validateHelperText()}</span>}
          </div>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <CancelButton />
            <SaveButton
              loading={loading}
              disabled={isDisabled}
              handleEdit={handleEdit}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const EditButton = ({ editDialogRef }) => {
  const theme = useTheme() as FTSTheme;

  return (
    <IconButton
      className="editDialog__editIcon"
      onClick={() => {
        editDialogRef.current?.open();
      }}
    >
      <ReactSVG
        src={EditIcon}
        beforeInjection={(svg) => {
          svg.setAttribute("style", `width: 16px; height: 16px; fill: ${theme.icon.color};`);
        }}
      />
    </IconButton>
  );
};

export default forwardRef(EditDialog);
