import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { useStoreActions } from "../../../../hooks";
import { FrameStyleProps } from "../../../../interfaces/IFrameAndCoverStyle";
import { IInsertDetail } from "../../../../interfaces/IInsertDetail";
import { isHorizontal } from "../../InsertConfiguration/Tiles/limitChar";
import "./Cover.css";
import { ROCKER, TWO_ROCKERS } from "../../../../constants/InsertType";
import { ROTATION_270_DEGREE, ROTATION_90_DEGREE } from "../../../../constants/NumberValues";

interface ICoverInputProps extends HTMLAttributes<HTMLDivElement> {
  cover: number;
  showMessageOnImageNotFound?: boolean;
  designCode: string;
  isHorizontal: boolean;
  insertDetail: IInsertDetail;
  coverRefs?: any;
}

const Cover: React.FC<ICoverInputProps> = (props) => {
  const { cover, showMessageOnImageNotFound: showMessageOnNotFoundImage, children, insertDetail, ...rest } = props;

  const { t } = useTranslation();

  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);

  const coverCode = insertDetail.insertColor?.code;

  const isRotate =
    props.insertDetail.rotate === ROTATION_90_DEGREE || props.insertDetail.rotate === ROTATION_270_DEGREE;
  const isRocker =
    props.insertDetail.insertType?.name === ROCKER || props.insertDetail.insertType?.name === TWO_ROCKERS;

  const getBackgroundUrl = () => {
    const source = `${process.env.REACT_APP_BLOB_STORAGE_URL}/images/inserts/${coverCode}/img.png`;

    var img = new Image();
    img.src = source;
    img.onerror = () => {
      if (showMessageOnNotFoundImage!) {
        thunkOpenSnackBar({
          message: t("cover.get_background_url.message"),
          severity: "info",
        });
        return;
      }
    };

    return `url(${source})`;
  };

  const getBackgroundImageProps = () => {
    const designCode = props.designCode;
    if (isRotate && isRocker) {
      return { backgroundSize: FrameStyleProps.get(designCode)?.cover.backgroundSize };
    }
    return {
      backgroundImage: getBackgroundUrl(),
      backgroundSize: FrameStyleProps.get(designCode)?.cover.backgroundSize,
      width: FrameStyleProps.get(designCode)?.cover.width,
      height: FrameStyleProps.get(designCode)?.cover.height,
    };
  };

  const getCoverStyleDefaultProps = () => {
    const designCode = props.designCode;
    return {
      width: FrameStyleProps.get(designCode)?.cover.width,
      height: FrameStyleProps.get(designCode)?.cover.height,
    };
  };

  const getCoverItemStyleProps = () => {
    const designCode = props.designCode;
    return {
      marginTop: FrameStyleProps.get(designCode)?.cover.coverItems[cover].marginTop,
      marginRight: FrameStyleProps.get(designCode)?.cover.coverItems[cover].marginRight,
      marginBottom: FrameStyleProps.get(designCode)?.cover.coverItems[cover].marginBottom,
      marginLeft: FrameStyleProps.get(designCode)?.cover.coverItems[cover].marginLeft,
    };
  };

  const getFutureDesignOrientation = () => {
    if (props.isHorizontal) return { transform: "rotate(0deg)" };

    return { transform: "rotate(270deg)" };
  };

  const getTransformProps = () => {
    return `rotate(${insertDetail?.rotate ?? 0}deg)`;
  };

  return (
    <>
      {insertDetail && (
        <div
          {...rest}
          className={`
          frame-cover 
          frame-cover-${cover}`}
          style={{
            ...getCoverStyleDefaultProps(),
            ...getCoverItemStyleProps(),
            ...getFutureDesignOrientation(),
          }}
        >
          {isRotate && isRocker && (
            <div
              className="rocker-rotated-background"
              style={{
                backgroundImage: getBackgroundUrl(),
                ...getBackgroundImageProps(),
              }}
            />
          )}
          <div
            data-coverdesignid={insertDetail.coverDesignId}
            ref={(ref) => {
              if (props.coverRefs) props.coverRefs.current[props.cover] = ref;
            }}
            style={{
              ...getBackgroundImageProps(),
            }}
          >
            <div
              style={{
                transform: getTransformProps(),
              }}
              data-coverorientation={isHorizontal(insertDetail!.rotate ?? 0) ? "horizontal" : "vertical"}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cover;
