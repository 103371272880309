import axios from "axios";
import store from "../store";
import logging from "./logging";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(async (requestConfig) => {
  let access_token = localStorage.getItem("[FTS_Customisation_Center][access_token]");

  if (access_token != null) {
    requestConfig.headers.set('Authorization', `Bearer ${access_token}`);
  }

  return requestConfig;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/session-expired";

      store.persist
        .clear()
        .then(() => {
          localStorage.removeItem("[FTS_Customisation_Center][access_token]");
          localStorage.removeItem("[FTS_Customisation_Center][refresh_token]");
          localStorage.removeItem("[FTS_Customisation_Center][user_data]");
          localStorage.removeItem("@FTS:CountryDialog");
        })
        .catch((err) => logging.error(err));
    }

    throw error;
  }
);

export default axiosInstance;
